<template>
  <div class="page page--create-new-password animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated"/>
      <div class="box-container animate__animated">
        <div class="box-content">
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div class="animate__animated">
              <p>Przyjeliśmy Twoje zamówienie</p>
              <p><small>Twoje zamówienie zostało przyjęte przez restaurację i jest w trakcie przygotowywania 😋</small></p>
            </div>
            <div class="animate__animated">
              <Btn v-if="id" @click="goToOrder">Idź do zamówienia</Btn>
              <Btn v-else @click="goToOrder">Idź do zamówień</Btn>
            </div>
          </SeqEntrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
  </div>
</template>

<script>
import SeqEntrance from "@/components/SeqEntrance";
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";

export default {
  name: "PaymentSuccess",
  components: {SeqEntrance,PlainFooter, StickyHeader, Logo, Btn},
  props: {
    id: String,
  },
  data() {
    return {};
  },
  methods: {
    goToOrder() {
      if (this.id) {
        this.$router.push({ name: 'Order', params: { id: this.id } });
      } else {
        this.$router.push({ name: 'Orders' });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .box-content {
    padding: 0 15px 15px 15px;
    max-width: 600px;
    width: 100%;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
}
.flex-fill {
  flex: 1;
}
</style>
